<template>
    <div>
      <filter-swapper :trigger="selectedRows.length === 0" id="pudos_swapper" :buttons="buttons" :controlHeightButtons="controlHeight"  v-if="loading.filter">
        <template #slot1>
          <form-render ref="formRenderFilter" :key="keyFormRender" class="px-0" :fields="fields" :buttonSend="buttonSend" :form.sync="formFilter" @send="filterList"
            containerButtonsClass="col-md-4 col-xl-3 mt-2 mb-1 d-flex align-items-center">
            <template #buttons>
              <b-button variant="outline-light" class="ml-2" v-b-tooltip.hover title="Limpiar filtros" @click="cleanFilter" :disabled="loading.first"><feather-icon icon="RefreshCwIcon"/></b-button>
              <b-button class="lnk lnk-primary search-advanced padding-formatter" variant="link" @click="openFilterAdvanced()" :disabled="loading.first">{{$t('Búsqueda avanzada')}}</b-button> 
            </template>
             <template #extra>
              <div class="col-md-12">
                <div class="row justify-content-end flex-mobile-bottom">
                    <b-button class="push-right separation-buttons" variant="success" :disabled="loading.excel" @click="downloadExcel()">
                      <feather-icon v-if="!download" icon="DownloadIcon"/>
                      <i v-else :class="['fa', 'fa-spinner', 'fa-spin']"/>
                      {{$t('Exportar')}}
                    </b-button>
                    <b-button v-if="showCreatePudo" variant="warning" class="push-right separation-buttons" :disabled="loading.first" @click="openNewPudo()"><feather-icon icon="PlusIcon"/> {{$t('Crear Puntos Retiro')}}</b-button>             
                </div>
              </div>
              <div class="col-md-12">
             <form-render
             :key="keyFormRender" class="px-0" :fields="fieldsType" :form.sync="formType"
             containerButtonsClass="col-md-4 col-xl-3 mt-2" 
            >
            </form-render>
              </div>
            </template>
            
          </form-render>
        </template>
      </filter-swapper>
      <div v-if="!loading.first">
        <table-render 
        :key="keyTableRender"
        :schema="schema" 
        :rows.sync="rows"
        :stickyHeader="`calc(100vh - 2.5rem)`"
        :actions="actions"
        id="pudos"
        :tableClass="'with-scroll'"
        :loading="loading.pudos"
      >
      <template #map="scope">
          <a v-if="scope.rowdata.address.location" @click="openMaps(scope.rowdata.id)" class="lnk lnk-primary">{{scope.rowdata.address.full_address}}</a>
          <span v-else>{{scope.rowdata.address.full_address}}</span>
      </template>
      <template #isActive="scope">
        <span class="table_dot--state" :class="getStatusClass(scope.rowdata.activated)"></span> {{ scope.rowdata.activated ? 'Habilitado' : 'Deshabilitado' }}
      </template>
      <template #type="scope">
        <span>{{ scope.rowdata.owner.organization ? scope.rowdata.owner.organization.organization_type.name : scope.rowdata.owner.organization_type.name }}</span>
      </template>
      <template #mkp="scope">
          <span> {{ scope.rowdata.owner.organization_type || scope.rowdata.owner.organization.organization_type.name === "E-Commerce" ?  '-' : scope.rowdata.owner.organization.name}} </span>
        </template>
      </table-render>
      <pagination :pagination="pagination" :noDigits="false" :showSize="true" :disabled="loading.pudos"/>
    </div>
      <!-- <b-skeleton height="40px" width="100%" class="mt-2 mb-2" v-show="loading.first"/> -->
      <div class="table-render-skeleton" v-show="loading.first">
        <b-skeleton-table   
          :rows="pagination.limit || 10"    
          :columns="schema.length || 10"
          :table-props="{ }"/>
      </div>
      <modal-maps ref="modalMaps" :pudo.sync="currentPudo" :countries="countries"></modal-maps >
      <modal-timetable ref="modalTimetable" :timetable.sync="currentTimetable"></modal-timetable >
      <modal-filter-advanced 
        ref="modalFilterAdvanced" 
        @send="filterList" 
        :searchNameCourier="searchNameCourier" 
        :filter.sync="currentFilter" 
        :formattedCountry="formattedCountry" 
        :mySession="mySession" 
        :filterPudosByQuery="filterPudosByQuery">
      </modal-filter-advanced>
      <modal-create-pudo ref="modalCreatePudo" :pudo.sync="currentNewPudo" :carriers="carriers" :getPudos="getPudos" :pagination="pagination"></modal-create-pudo>
  </div>
</template>
<script>
import { environment } from '@/environments/environment'
import BaseServices from '@/store/services/index'
import { mapGetters } from 'vuex'
import ModalMaps from './ModalMaps.vue'
import ModalTimetable from './ModalTimetable.vue'
import ModalFilterAdvanced from './ModalFilterAdvanced.vue'
import ModalCreatePudo from './ModalCreatePudo.vue'
export default {
  name: 'pudos',
  components: {ModalMaps, ModalTimetable, ModalFilterAdvanced, ModalCreatePudo},
  data () {
    return {
      optionsType: [
        { id: 1, text: this.$t('Courier'), name: 'Courier'}
      ],
      buttons: [],
      currentPudo: {},  
      currentNewPudo : {},
      currentTimetable: {},
      currentFilter: {},
      controlHeight: { class: 'row mb-2 spacing-label-field'},
      pagination: {
        page: 1,
        total: 40,
        limit: 10
      },
      selectedRows: [],
      schema: [],
      actions: [],
      rows: [],
      buttonSend: {icon: 'SearchIcon', color: 'warning', title: 'Filtrar', disabled: true},
      fields: [],
      fieldsType: [],
      formFilter: {},
      formType: {},
      payloadFilter: {},
      loading: {
        first: false,
        pudos: false,
        filter: false,
        excel: false
      },
      download: false,
      keyTableRender: 0,
      keyFormRender: 0,
      option: {
        show_checkbox: false
      },
      nextLevelData: {},
      typePudo: null,
      baseService: new BaseServices(this),
      internalLevels: {
        cl: {
          required: [3],
          display: [3]
        },
        co: {
          required: [1, 2],
          display: [1, 2]
        },
        pe: {
          required: [1, 2, 3],
          display: [2, 3]
        },
        ar: {
          required: [1, 2, 3],
          display: [1, 2, 3]
        },
        mx: {
          required: [1, 2, 3, 4],
          display: [1, 2, 4]
        },
        ec: {
          required: [1, 2, 3],
          display: [1, 2, 3]
        }
      },
      schemaByType : {
        seller: [
          {label:  'Empresa', sortable: true, key: 'owner.name'},
          {label: 'Tipo', sortable: true, useSlot: true, key: 'type'},
          {label: 'Marketplace', sortable: true, useSlot: true, key: 'mkp' }
        ],
        courier: {label:  'Courier', sortable: true, key: 'owner.name'}
      },
      showCreatePudo: true,
      formattedCountry: null,
      CONDITIONS: Object.freeze({
        SHOW: 'show',
        HIDE: 'hide',
        SELLER: 'seller',
        COURIER: 'courier',
        ADD: 'add',
        REMOVE: 'remove'
      }),
      forceSetMxLevel4: false,
      countryConfigs: {
        1: [{ label: 'Comuna', sortable: true, key: 'address.place.level1.name' }],
        2: [
          { label: 'Estado', sortable: true, key: 'address.place.level4.name' },
          { label: 'Municipio', sortable: true, key: 'address.place.level3.name' },
          { label: 'Colonia', sortable: true, key: 'address.place.level1.name' }
        ],
        3: [
          { label: 'Departamento', sortable: true, key: 'address.place.level2.name' },
          { label: 'Municipio', sortable: true, key: 'address.place.level1.name' }
        ],
        4: [
          { label: 'Provincia', sortable: true, key: 'address.place.level2.name' },
          { label: 'Distrito', sortable: true, key: 'address.place.level1.name' }
        ]
      },
      filterPudosByQuery: false
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      pudos: 'getPudosForType',
      countries: 'getCountries',
      levels: 'getLevels',
      generalLoading: 'getLoading',
      shippersByOrganizationSimplifiedQueryParams: 'getShippersByOrganizationSimplifiedQueryParams',
      carriers: 'getCarriersPudos'
    })
  },
  watch: {
    generalLoading: {
      handler() {
        this.loading.pudos = !!this.generalLoading.getPudosForType
      },
      deep: true
    },
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    countries () {
      this.setCountries()
    },
    pudos() {
      this.rows = this.pudos.rows.map(el => {
        return {
          ...el,
          created_at_format: this.$options.filters.moment(el.created_at, 'DD/MM/YYYY'),
          address: {
            ...el.address,
            location: !!el.address.location && !!el.address.location?.latitude && !!el.address.location?.longitude ? el.address.location : null
          }
        }
      })
      this.pagination.total = this.pudos.pagination.meta.data_count
      this.pagination.page = this.pudos.pagination.meta.page
      this['pagination.limit'] = this.pudos.pagination.meta.page_count
    },
    shippersByOrganizationSimplifiedQueryParams () {
      this.setSelectOptions('owner.id', { options: this.shippersByOrganizationSimplifiedQueryParams })
    },
    'pagination.page' () {
      this.getPudos(this.typePudo.name)
    },
    'pagination.limit' () {
      if (this.pagination.page === 1) this.getPudos(this.typePudo.name)
    }
  },
  mounted () {
    this.getAllInitialData()
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    }, 
    setInitialData () {
      this.setSchema()
      this.sessionUser = this.$session.get('cas_user')
      this.isAdmin = ['superadmin', 'admin'].includes(this.sessionUser.role)
      this.filterPudosByQuery = !!this.isAdmin && !!this.$route.query?.company_id
      this.isMarketplace = ['marketplace'].includes(this.sessionUser.role)
      this.actions = [
        {action: id => this.openTimetable(id), icon: 'ClockIcon', color:'primary', text: 'Horarios'},
        {action: id => this.goToDetailsPudo(id), icon: 'FileTextIcon', color: 'primary', text: 'Editar' }
      ] 
      this.actionDelete = {action: id => this.confirmDeletePudo(id), icon: 'TrashIcon', color: 'danger', text: 'Eliminar' }   
      this.actions.push(this.actionDelete) 
      this.setFields() 
      this.optionTypeRadioButtom()
      this.formType.type = this.optionsType[0]
      this.personalizeTableForRol() 
      // Llamar una unica vez el endpoint de obtener los seller de un marketplace
      if (['marketplace'].includes(this.sessionUser.role)) this.setShippers()
      this.setCountries()
      if (!!this.isAdmin) this.$store.dispatch('fetchService', { name: 'getCarriersPudos'})
    },
    setCountries () {
      if (this.countries && !!this.countries.length) {
        this.formattedCountry = this.countries.filter(item => {
          return item.code.toLowerCase() !== 'ar' && item.code.toLowerCase() !== 'ec'
        }) 
        this.setSelectOptions('country', { options: this.formattedCountry })
      }
    },
    setFields () {
      this.fields = [
        {fieldType: 'FieldInput', name: 'name__like__or__code__like', label: 'Nombre/Código Punto de retiro', placeholder:'Ingrese Nombre o Código Punto de retiro'},
        {fieldType: 'FieldAutocomplete', name: 'courier.id', label: 'Courier', searchOnType: { fx: this.searchNameCourier, nChar: 3, debounce: 300, allowSearchAsValue: true, persistSearch: true }, placeholder:'Ingrese Courier', clearable: true},
        {fieldType: 'FieldSelect', name: 'country', containerClass: 'hidden' }
      ]
      this.fieldsType = [   
        {fieldType: 'FieldRadio', name: 'type', type: 'type', align: 'h', options: this.optionsType, change: this.changeType, containerClass: 'col-12', disabled: true}
      ]
    },
    setSchema () {
      this.schema = [
        {label: 'Código PUDO', sortable: true, key: 'code'},
        {label: 'Nombre', sortable: true, key: 'name'},
        {label: 'Dirección', key: 'map', useSlot: true},
        {label: 'Localidad', sortable: true, key: 'address.place.level1.name' },
        {label: 'Estado', useSlot: true, key: 'isActive'},
        {label: 'Fecha creación', sortable: true, key: 'created_at_format'},
        {label: 'Acciones', key: 'actions', class: ['text-center']}
      ]
    },
    optionTypeRadioButtom () {
      if (this.isAdmin) this.optionsType.push({ id: 2, text: this.$t('Tienda'), name:'Seller,Organization'})
      if (['marketplace'].includes(this.sessionUser.role)) this.optionsType.splice(0, 0, { id: 3, text: this.$t('Mis tiendas'), name:'Organization'}, { id: 4, text: this.$t('Tiendas seller'), name:'Seller'})
      if (['ecommerce', 'seller'].includes(this.sessionUser.role)) this.optionsType.splice(0, 0, { id: 3, text: this.$t('Mis tiendas'), name:'Seller'}, { id: 5, text: this.$t('Tiendas MKP'), name:'Organization'})
    },
    personalizeTableForRol () {
      this.typePudo = this.optionsType[0]
      if (this.isAdmin) {
        const index = this.getPositionFindIndex(this.schema, 'label', 'Nombre') + 1
        this.schema.splice(index, 0, this.schemaByType[this.typePudo.name.toLowerCase()])
        if (this.filterPudosByQuery) {
          this.setPudosByQueryParams()
        } else {
          this.getPudos(this.typePudo.name)
        }
      } else {
        this.setOwnerForNonAdminUsers()
        this.changeSchemaByCountry()
        this.getPudos(this.typePudo.name)
        this.indexCourier = this.getPositionFindIndex(this.fields, 'name', 'courier.id')
        this.fields.splice(this.indexCourier, 1)
        this.changeCountry('country', this.sessionUser.country)
      }
      this.loading.filter = true
      this.loading.first = true  
      this.loading.excel = true
    },
    setPudosByQueryParams () {
      this.formType.type = this.optionsType[1]
      this.keyFormRender++
      this.changeType('type', this.optionsType[1])
    },
    getOrganization (value) {
      this.baseService.callService('getOrganization', null, { id: this.$route.query.company_id })
        .then(resp => {
          this.formFilter.country = resp.data.country
          this.payloadFilter['country.id'] = resp.data.country.id
          if (resp.data.organization_type.id === 2) {
            this.payloadFilter['owner.id'] = resp.data.id
          } else {
            this.payloadFilter['owner.organization.id'] = resp.data.id
          }
          if (this.$route.query?.seller_id) this.payloadFilter['owner.id'] = this.$route.query.seller_id
          this.getPudos(value) 
        })
        .catch(err => {
          console.error(err)
        })
    },
    getPudos (params) {
      const queryParams = {
        ...this.payloadFilter,
        'paginate_by': this.pagination.limit,
        'page': this.pagination.page,
        'owner.type': params
      }
      this.$store.dispatch('fetchService', { name: 'getPudosForType', queryParams, onSuccess: () => { this.disabledFieldsRadio(false) }})
    },
    changeType (name, value) {
      this.typePudo = value
      this.clearChangeType()
      this.setDinamicFieldSchemaForType(value)
      this.keyFormRender++
      // Validamos si la columna localidad existe si no es asi ha sido modificada para mostrar otros levels
      const indexLocation = this.getPositionFindIndex(this.schema, 'label', 'Localidad')
      // Si se hicieron cambios en la tabla la reiniciamos a como esta originalmente
      if (!!this.isAdmin && indexLocation === -1) this.resetSchema()
      if (this.pagination.page !== 1) this.pagination.page = 1
      else if (this.filterPudosByQuery) {
        this.getOrganization(value.name)
      }
      else this.getPudos(value.name)   
    },
    setDinamicFieldSchemaForType (value) {
      // Si no es admin
      // Ocultamos/Mostramos columna de fecha de creación/ acción de eliminar segun el tipo de pudo seleccionado
      if (!this.isAdmin) [1, 5].includes(value.id) ? this.showHideDateColumnAndDeleteAction(this.CONDITIONS.HIDE) : this.showHideDateColumnAndDeleteAction(this.CONDITIONS.SHOW)
      // Mostrar o ocultar boton de creación 
      // Si es admin se muestra, sino solo para tipo 3(Mi tienda) o 4(Tiendas seller) lo mostramos
      this.showCreatePudo = !!this.isAdmin || [3, 4].includes(value.id)
      // Seleccionado tipo de pudo COURIER
      this.isTypeCourier = value.id === 1;
      // En caso de no querer mostrar el nombre del MKP quitar el 5 para que se vaya oculte la columna
      // Función para mostrar/ocultar columnas en la tabla Empresa/Courier...
      [1, 2, 4, 5].includes(value.id) ? this.showHideDinamicColumns(this.CONDITIONS.SHOW, this.CONDITIONS[this.isTypeCourier ? 'COURIER' : 'SELLER']) : this.showHideDinamicColumns(this.CONDITIONS.HIDE);
      // Función para agregar o remover campos en los filtros que se encuentran en la parte superior de la tabla
      [1, 2, 4].includes(value.id) ? this.addOrRemoveDinamicFields(this.CONDITIONS.ADD, value.id) : this.addOrRemoveDinamicFields(this.CONDITIONS.REMOVE, value.id)
    },
    addOrRemoveDinamicFields (addOrRemove, type_id) {
      const fieldsDinamics = {
        1: {fieldType: 'FieldAutocomplete', name: 'courier.id', label: 'Courier', searchOnType: { fx: this.searchNameCourier, nChar: 3, debounce: 300, allowSearchAsValue: true, persistSearch: true }, placeholder:'Ingrese Courier', clearable: true},
        2: {fieldType: 'FieldSelect', name: 'country', label: 'País', options: this.formattedCountry, addFlags: true},
        4: {fieldType: 'FieldSelect', name: 'owner.id', label: 'Seller', useLabel: true, placeholder: 'Seleccione Seller', useSkeleton: false}
      }
      this.indexCourier = this.getPositionFindIndex(this.fields, 'name', 'courier.id') 
      this.indexCountry = !this.isAdmin ? this.getPositionFindIndex(this.fields, 'name', 'levels') : this.getPositionFindIndex(this.fields, 'name', 'country') 
      // Agregar un nuevo field dinamico 
      if (addOrRemove === this.CONDITIONS.ADD) {
        this.fields.splice(this.isTypeCourier ? this.indexCountry : this.indexCourier, 1, fieldsDinamics[type_id])
        // Seteamos los sellers de un marketplace
        if (type_id === 4) {
          this.setSelectOptions('owner.id', { options: this.shippersByOrganizationSimplifiedQueryParams })
        }
      } 
      // Eliminar campos dinámicos
      else if (addOrRemove === this.CONDITIONS.REMOVE) {
        this.fields.splice(this.indexCourier, 1)
        // Como no tiene para seleccionar un país llamamos la función loadedLevels para setear los levels segun el país de la sesión del usuario
        this.loadedLevels()
      }
    },
    showHideDateColumnAndDeleteAction (type) {
      const indexDate = this.getPositionFindIndex(this.schema, 'label', 'Fecha creación')
      const indexStatus = this.getPositionFindIndex(this.schema, 'label', 'Estado')
      const indexActionEdit = this.getPositionFindIndex(this.actions, 'text', 'Editar') + 1
      const hideActionDelete = !this.actions[indexActionEdit]

      switch (type) {
      case this.CONDITIONS.SHOW:
        if (indexDate === -1) this.schema.splice(indexStatus + 1, 0, {label: 'Fecha creación', sortable: true, key: 'created_at_format'})
        if (hideActionDelete) this.actions.splice(indexActionEdit, 0, this.actionDelete)
        break

      case this.CONDITIONS.HIDE:
        if (indexDate !== -1) this.schema.splice(indexStatus + 1, 1)
        this.actions.splice(indexActionEdit, 1)
        break

      default:
        break
      }
    },
    showHideDinamicColumns (type, owner) {
      if (this.isAdmin) {
        this.indexSchemaUser = this.getPositionFindIndex(this.schema, 'label', 'Nombre') + 1
        // Si estamos visualizando el listado de carriers agregamos las columnas que corresponden(Courier) desde la columna Nombre
        if (type === this.CONDITIONS.SHOW && owner === this.CONDITIONS.COURIER) this.schema.splice(this.indexSchemaUser, 3, this.schemaByType.courier) 
        // Si estamos visualizando el listado de tiendas agregamos las columnas que corresponden(Empresa/Tipo/MKP) desde la columna Nombre
        else if (type === this.CONDITIONS.SHOW && owner === this.CONDITIONS.SELLER) this.schema.splice(this.indexSchemaUser, 1, ...this.schemaByType.seller)
      } else {
        // Seteamos el país segun la sesión de usuario
        this.formFilter.country = this.sessionUser.country
        // Verificamos si hay columnas dinamicas validando si existe la columna Empresa o Courier
        this.existenceOfDynamicColumns = this.schema.findIndex(el => el.label === 'Empresa' || el.label === 'Courier')
        // Seteamos la columna que corresponde segun el dueño (Seller/Courier)
        const column = owner === this.CONDITIONS.SELLER ?  this.schemaByType.seller[0] : this.schemaByType.courier
        // Si debemos ocultar una columna primero validamos su existencia ysi existe la ocultamos
        if (type === this.CONDITIONS.HIDE) this.schema.splice(this.indexSchemaUser, this.existenceOfDynamicColumns !== -1 ? 1 : 0) 
        // Si debemos mostrar una columna dinamica validamos si ya existe una para eliminarla y luego agregar la nueva columna correspondiente
        else if (type === this.CONDITIONS.SHOW) this.schema.splice(this.indexSchemaUser, this.existenceOfDynamicColumns !== -1 ? 1 : 0, column) 
      } 
    },
    setShippers () {
      this.formFilter['owner.id'] = null
      this.formFilter = {
        ...this.formFilter,
        ['owner.id']: null
      }
      const queryParams = {}
      const params = {
        organization_id: this.sessionUser.organization.id 
      }
      this.$store.dispatch('fetchService', { name: 'getShippersByOrganizationSimplifiedQueryParams', queryParams, params})     
    },
    disabledFieldsRadio (disabled, loading = false) {
      this.fieldsType[0].disabled = disabled
      this.buttonSend.disabled = disabled
      this.loading.first = loading
      this.loading.excel = loading
    },
    clearChangeType () {
      this.rows = [] 
      this.formFilter = {}
      if (this.isAdmin) this.payloadFilter = {}
      else this.setOwnerForNonAdminUsers()
      this.disabledFieldsRadio(true, true)
      this.$refs.modalFilterAdvanced.cleanForm()
    },
    setOwnerForNonAdminUsers () {
      this.payloadFilter = {
        ['owner.id']: this.isMarketplace || this.typePudo.id === 5 ? this.sessionUser.organization?.id : this.sessionUser.shipper?.id, 
        ['country.id']: this.sessionUser.country?.id, 
        ['owner.organization.id']: this.sessionUser.organization?.id
      };
      [3, 5].includes(this.typePudo.id) || (!this.isMarketplace && [1].includes(this.typePudo.id)) ? delete this.payloadFilter['owner.organization.id'] : delete this.payloadFilter['owner.id']
    },
    confirmDeletePudo (_id) {
      const pudo = this.rows.filter(({id}) => id === _id)[0]
      this.$yesno(this.$t('msg-pregunta-eliminar-pudo', {name: pudo.name}), () => this.deletePudo(_id))
    },
    deletePudo (id) {
      this.$store.dispatch('fetchService', { name: 'deletePudo', params: { id }, onSuccess: () =>  {
        this.getPudos(this.typePudo.name)
      }})
    },
    openMaps(_id) {
      this.currentPudo = this.rows.filter(({id}) => id === _id)[0]
      this.$bvModal.show('modalMaps')
    },
    openTimetable(_id) {
      this.currentTimetable = this.rows.filter(({id}) => id === _id)[0]
      this.$bvModal.show('modalTimetable')
    },
    goToDetailsPudo(id) {
      const currentPudo = this.rows.find(value => value.id === id)
      window.open(`${environment.platformUrl}/pudos-beta/${currentPudo.id}`, '_blank')
    },
    openFilterAdvanced(_id) {
      this.currentFilter = {
        type: this.typePudo,
        ...this.formFilter
      }
      this.$bvModal.show('modalFilterAdvanced')
    },
    openNewPudo () {
      this.currentNewPudo = {type: this.typePudo} 
      this.$bvModal.show('modalCreatePudo')
    },
    resetPudos () {
      if (!!this.filterPudosByQuery) {
        this.$router.replace({ name: 'pudos', query: {} })
        // Esto debe cambiarse cuando sea solo Madagascar
        parent.postMessage({madagascarCommand:'clearQueryParams'}, '*')
        this.filterPudosByQuery = false
      }
      this.$refs.modalFilterAdvanced.cleanForm()
    },
    // Limpio todos lo filtros
    cleanFilter () {
      this.disabledFieldsRadio(true)
      this.formFilter = this.isAdmin ? {} : { country: this.formFilter.country }
      this.payloadFilter = {} 
      if (!this.isAdmin) {
        this.setOwnerForNonAdminUsers() 
        const fieldLevel = this.fields[this.getPositionFindIndex(this.fields, 'name', 'levels')]
        if (fieldLevel) fieldLevel.country.requiredLevels = [0] 
      } 
      this.keyFormRender++
      // Validamos si la columna localidad existe si no es asi ha sido modificada para mostrar otros levels
      const indexLocation = this.getPositionFindIndex(this.schema, 'label', 'Localidad')
      // Si se hicieron cambios en la tabla la reiniciamos a como esta originalmente
      if (!!this.isAdmin && indexLocation === -1) this.resetSchema()
      if (this.pagination.page !== 1) this.pagination.page = 1
      else this.getPudos(this.typePudo.name)
      this.resetPudos()
    },
    // Cambiar logica cuando sea posible filtrar no solo por el ultimo place del país
    filterList (form) {  
      this.disabledFieldsRadio(true)
      this.payloadFilter = {}
      const application_form = !!form.form ? form.form : form
      if (!this.isAdmin) this.setOwnerForNonAdminUsers()
      if (!!form.country) {
        this.lastLevelOfPlace = [1, 4].includes(form.country?.id) && form.level3_pudo ? form.level3_pudo : [3].includes(form.country?.id) && form.level2_pudo ? form.level2_pudo : [2].includes(form.country?.id) && form.level4_pudo ? form.level4_pudo : null
        application_form['country.id'] = form.country
        if (form?.level2_pudo || form?.level3_pudo || form?.level4_pudo) {
          application_form['address.place.level1.id'] = this.lastLevelOfPlace
          delete application_form['level1_pudo']
          delete application_form['level2_pudo']
          delete application_form['level3_pudo']
          delete application_form['level4_pudo']
        }
        delete application_form['country']
      }
      Object.keys(application_form).map(key => {
        this.payloadFilter[key] = application_form[key].id || application_form[key]
      })
      // Cambiamos las columnas de la tabla segun el país filtrado
      if (!!this.isAdmin && !!this.payloadFilter['country.id']) this.changeSchemaByCountry()
      // Validamos si la columna localidad existe si no es asi ha sido modificada para mostrar otros levels
      const indexLocation = this.getPositionFindIndex(this.schema, 'label', 'Localidad')
      // Si se hicieron cambios en la tabla la reiniciamos a como esta originalmente
      if (!!this.isAdmin && indexLocation === -1 && !this.payloadFilter['country.id']) this.resetSchema()
      // Seteo de campos con los valores ingresados en modal B.A
      if (!!form?.form) {
        if (this.typePudo.id === 2) this.formFilter['country'] = form?.form['country.id']
        this.formFilter['name__like__or__code__like'] = form?.form['name__like__or__code__like']
        this.formFilter['owner.id'] = form?.form['owner.id']
        this.formFilter['courier.id'] = form?.form['courier.id']
        this.formFilter['level1_pudo'] = form?.original['level1_pudo']
        this.formFilter['level2_pudo'] = form?.original['level2_pudo']
        this.formFilter['level3_pudo'] = form?.original['level3_pudo']
        this.formFilter['level4_pudo'] = form?.original['level4_pudo']
        if (!this.isAdmin && (this.formFilter.level2_pudo || this.formFilter.level1_pudo) && [3, 5].includes(this.typePudo.id)) this.setChangedPlacesInModalAdvance()
      }
      if (this.pagination.page !== 1) this.pagination.page = 1
      else this.getPudos(this.typePudo.name)
      this.$bvModal.hide('modalFilterAdvanced')
      this.resetPudos()
    },
    setChangedPlacesInModalAdvance () {
      const country = this.internalLevels[this.country.code.toLowerCase()].display
      const last = country[country.length - 1] 
      if (['co', 'pe'].includes(this.country.code.toLowerCase())) {
        this.changePlaceLevel(`level${last}_pudo`, null, 'undefined_pudo', true)
      }
      if (['mx'].includes(this.country.code.toLowerCase())) {
        if (this.formFilter.level2_pudo) this.forceSetMxLevel4 = true
        this.changePlaceLevel('level1_pudo', this.formFilter.level1_pudo, 'level2_pudo', true)
      }
      const countryCode = this.formFilter.country.code.toLowerCase()
      const mx = ['mx'].includes(countryCode)
      this.requireLastLevel(countryCode, mx)
    },
    searchNameCourier (value) {
      const params = {}
      const queryParams = !!this.isAdmin ? { name__like: value } : {
        'name__like': value, 
        'country.id': this.sessionUser.country.id, 
        'owner.id': this.sessionUser.shipper?.id,
        'owner.organization.id': this.sessionUser.organization?.id
      }
      this.isMarketplace ? delete queryParams['owner.id'] : delete queryParams['owner.organization.id']
      return this.baseService.callService('getCarriersName', queryParams, params)
        .then(resp => {
          const response = resp.data.map(el => ({...el, text: el.name}))
          return response
        })
        .catch(err => {
          return err
        })
    },
    changeCountry (name, value) {
      this.fields = this.fields.filter(el => !el.name.includes('level') && !el.name.includes('skeleton'))
      this.country = value
      this.prevTabIndex = -1
      this.formFilter = {
        ...this.formFilter,
        country: value
      }
      //limpia todo
      if (!value?.id) return null
      //Guardo los campos normales
      let indexFromCountry = this.getPositionFindIndex(this.fields, 'name', name)

      indexFromCountry++
      const display = this.internalLevels[this.country.code.toLowerCase()].display

      display.map(el => {
        this.fields.push({ name: `skeleton${el}`, useSkeleton: true, containerClass: 'col-md-4 col-xl-3' })
      })

      this.fields.splice(indexFromCountry + 1, 0)
      //Pego los campos originales
      if (value?.id) {
        this.keyServiceType++
        this.nextLevelData = {}
        if (['mx'].includes(value.code.toLowerCase())) {
          this.nextLevelData.level = 1
        }
        this.getLevels(value, false)
      }
      
    },
    getLevels (country, forceGetLevels) {
      const params = {country: country.code.toLowerCase() }
      const { queryParams, onSuccessFx } = this.getQueryParamLevel(country.code.toLowerCase())
      if (!['mx'].includes(params.country) || forceGetLevels) {
        this.$store.dispatch('fetchService', { name: 'getLevels', params, queryParams, onSuccess: (data1, data2) => {
          onSuccessFx(data1, data2)
          if (!!this.forceSetMxLevel4) {
            this.changePlaceLevel('level4_pudo', null, 'undefined_pudo', true); this.forceSetMxLevel4 = false
          }
        }})
      } else {
        this.$store.commit('setLevels', [])
        this.loadedLevels('getLevels')
      }
    },
    getQueryParamLevel (country_code) {
      let queryParams = {}
      let onSuccessFx = () => this.loadedLevels('getQueryParamLevel')
      if (['co', 'mx', 'pe'].includes(country_code)) {
        queryParams = {...this.nextLevelData}
        delete queryParams.next
        if (this.nextLevelData.next) {
          onSuccessFx = (data) => {
            this.setManualOptions(data.data.level1, this.nextLevelData.next)
          }
        }
      }
      return { queryParams, onSuccessFx }
    },
    searchLevels (name, value) {
      const level = name.split('_')[0].replace('level', '')
      const queryParams = {
        level,
        name: value
      }
      const params = {
        country: this.formFilter.country.code.toLowerCase()
      }
      if (this.formFilter[name.replace(level, level + 1)]) {
        queryParams.parent_id = this.formFilter[name.replace(level, level + 1)].id
      }
      this.$store.dispatch('fetchService', 
        { name: 'getLevels', params, queryParams, 
          onSuccess: (data) => this.setManualOptions(data.data?.level1 || [], name), hideAlert: true}
      )
    },
    loadedLevels (origin) {
      const full_places = this.$generateArrayPlacesFromTree(this.levels)
      this.country = this.isAdmin ? this.formFilter.country : this.sessionUser.country
      this.fields = this.fields.filter(el => el.name.indexOf('skeleton') === -1).filter(el => !el.name.includes('level'))
      const indexSplice = this.getPositionFindIndex(this.fields, 'name', 'country') + 1
      const country = {
        code: this.country.code.toLowerCase(),
        requiredLevels: [0],
        displayLevels: this.internalLevels[this.country.code.toLowerCase()].display,
        maxLevel: Math.max(...this.internalLevels[this.country.code.toLowerCase()].required),
        levels: full_places,
        loadLevelsOnSelection: false,
        stepByStep: ['co', 'mx', 'pe'].includes(this.country.code.toLowerCase()),
        level1Search: this.searchLevels
      }
      const fieldOrigin = {
        fieldType: 'FieldsLevels',
        name: 'levels',
        extraName: 'pudo',
        country,
        containerClass: 'col-md-4 col-xl-3',
        change: this.changePlaceLevel
      }
      this.fields.splice(indexSplice, 0, fieldOrigin)

    },
    changePlaceLevel (name, value, next, notSetForm) {
      if (!notSetForm) this.formFilter = {...this.formFilter, [next]: undefined}
      const countryCode = this.formFilter.country.code.toLowerCase()
      const mx = ['mx'].includes(countryCode)
      this.requireLastLevel(countryCode, mx, next)
      const level = name
      if (['co', 'mx', 'pe'].includes(countryCode)) {
        const currentLevel = (name === 'level2_pudo') && mx ? 3 : +name.split('_')[0].replace('level', '')
        this.nextLevelData = {
          level: this.internalLevels[countryCode].required.length === currentLevel ? currentLevel : currentLevel + 1,
          parent_id: this.internalLevels[countryCode].required.length === currentLevel ? this.formFilter[level.replace(`level${currentLevel}`, `level${currentLevel > 1 && currentLevel === 4 ? currentLevel - 2 : currentLevel > 1 && currentLevel !== 4 ? currentLevel - 1 : currentLevel}`)].id : value.id,
          next: this.internalLevels[countryCode].required.length === currentLevel ? name : next
        }
        if ((name === 'level2_pudo' || name === 'level4_pudo') && mx) {
          this.nextLevelData.grandparent_id = this.nextLevelData.parent_id
          delete this.nextLevelData.parent_id
        }
      }
      this.getLevels(this.formFilter.country, true)    
    },
    setManualOptions (places, fieldTo) {
      const full_places = this.$generateArrayPlacesFromTree(places)
      const level_num = +fieldTo.split('_')[0].replace('level', '')
      this.$refs.formRenderFilter.setManualOptions(fieldTo, full_places.filter(place => place.level_num === level_num))
    },
    setSelectOptions (name, { options }) {
      const index = this.getPositionFindIndex(this.fields, 'name', name)
      if (index !== -1) {
        this.fields[index].options = options
        this.keyFormRender++
      }
    },
    getPositionFindIndex (origin, type,  name) {
      return origin.findIndex(el => el[type] === name)
    },
    downloadExcel() {
      const queryParams = {
        ...this.payloadFilter,
        ['owner.type']: this.typePudo.name
      }
      this.loading.excel = true
      this.download = true
      const props = {
        name: 'downloadPudosExcel',
        fileName: 'Listado_pudos',
        queryParams,
        onSuccess: this.onLoadedExcel,
        onError: this.onLoadedExcel
      }
      this.$store.dispatch('fetchServiceGenerateExcel', props)
    },
    onLoadedExcel () {
      this.loading.excel = false
      this.download = false
    },
    resetSchema () {
      this.setSchema()
      this.indexSchemaUser = this.getPositionFindIndex(this.schema, 'label', 'Nombre') + 1
      if (this.typePudo.id === 1) this.schema.splice(this.indexSchemaUser, 0, this.schemaByType.courier) 
      if (this.typePudo.id === 2) this.schema.splice(this.indexSchemaUser, 0, ...this.schemaByType.seller) 
    },
    changeSchemaByCountry () {
      // Limpiamos la tabla
      this.resetSchema()
      // Mostramos segun el país las columnas correspondientes
      const index = this.getPositionFindIndex(this.schema, 'label', 'Localidad')
      const country_id = this.payloadFilter['country.id']
      if (this.countryConfigs[country_id]) {
        const columns = this.countryConfigs[country_id]
        this.schema.splice(index, 1, ...columns)
      }
    },
    // Es necesario para requirir el ultimo nivel solo en el caso que se realice un change en el primero
    // Cuando ya se pueda filtrar por cualquier level no deberia ser necesario
    requireLastLevel (countryCode, mx, next) {
      const levels = this.internalLevels[countryCode].display
      const penultimate = levels.slice(-2, -1)
      const last = levels[levels.length - 1]
      const indexLevel = this.getPositionFindIndex(this.fields, 'name', 'levels')
      const fieldLevelRequired = this.fields[indexLevel].country.requiredLevels
      // Requerir ultimo nivel
      if (!!this.formFilter[`level${penultimate}_pudo`] || (!!this.formFilter.level1_pudo && mx)) {
        if (fieldLevelRequired[0] === 0) {
          this.setRequiredLevels(indexLevel, last)
        }
      }
      // Eliminar requerido del ultimo nivel
      if ((!mx && next === 'null_pudo') || (mx && !this.formFilter.level1_pudo)) {
        if (fieldLevelRequired[0] !== 0) {
          this.setRequiredLevels(indexLevel, 0)
        } 
      }
    },
    setRequiredLevels (indexLevel, number) {
      this.fields[indexLevel].country.requiredLevels = [number]
      this.keyFormRender++
    },
    getStatusClass(activated) {
      return activated ? 'table_dot--state-ok' : 'table_dot--status-inactivated'
    }
  }
  
}
</script>
<style lang='scss'>
.search-advanced {
    text-decoration: underline !important;
    font-size: 1.1rem;
}
.separation-buttons {
    margin-right: 1rem;
}
.text-center div {
    display: flex;
}
.padding-formatter {
  padding: 0.8rem;
}
@media (max-width: 426px) {
  .flex-mobile-bottom {
    flex-direction: column;
  }
  .separation-buttons {
    margin-bottom: 1rem;
}
}
</style>
  