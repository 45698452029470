<template>
  <div>
    <filter-swapper :trigger="selectedRows.length === 0" id="deliveries_swapper" :buttons="buttons" :controlHeightButtons="controlHeight"  v-if="loading.filter">
      <template #slot1>
        <form-render ref="formRenderFilter" :key="keyFormRender" class="px-0" :fields="fields" :buttonSend="buttonSend" :form.sync="formFilter" @send="filterList"
          containerButtonsClass="col-md-4 col-xl-3 mt-2 mb-1 d-flex align-items-center">
          <template #buttons>
            <b-button variant="outline-light" class="ml-2" v-b-tooltip.hover title="Limpiar filtros" @click="cleanFilter" :disabled="loading.first"><feather-icon icon="RefreshCwIcon"/></b-button>
            <b-button class="lnk lnk-primary search-advanced" v-if="!isPudo" variant="link" @click="openFilterAdvanced()" :disabled="loading.first">{{$t('Búsqueda avanzada')}}</b-button> 
          </template>
           <template #extra>
            <div class="col-md-12">
              <div class="row justify-content-end flex-mobile-bottom">
                  <b-button class="push-right separation-buttons" variant="success" :disabled="loading.excel" @click="downloadExcel()">
                    <feather-icon v-if="!download" icon="DownloadIcon"/>
                    <i v-else :class="['fa', 'fa-spinner', 'fa-spin']"/>
                    {{$t('Exportar')}}
                  </b-button>
              </div>
            </div>
            <div class="col-md-12 mt-1">
              <form-render
              :key="keyFormRender" class="px-0" :fields="fieldsType" :form.sync="formType"
              containerButtonsClass="col-md-4 col-xl-3 mt-2" 
              >
              </form-render>
            </div>
          </template>
        </form-render>
      </template>
    </filter-swapper>
    <div v-if="!loading.first">
      <table-render 
      :key="keyTableRender"
      :schema="schema" 
      :actions="actions"
      :rows.sync="rows"
      :stickyHeader="`calc(100vh - 2.5rem)`"
      id="deliveries"
      :tableClass="'with-scroll'"
      :loading="loading.deliveries"
      :showCheckboxes="(isPudo || isAdmin) && typePudo.name === 'Received'" 
      :selectedRows.sync="selectedRows"
    >
    <template #status="scope">
      <span :class="`table_dot--state ${$options.filters.getStatusClasses()[scope.rowdata.status.id]}`"></span> {{scope.rowdata.status.name}}
    </template>
    <template #imported_id="scope">
      <b-button class="p-0" variant="link" @click="goTo(scope.rowdata.id)">{{scope.rowdata.imported_id}}</b-button>
    </template>
    </table-render>
    <pagination :pagination="pagination" :noDigits="false" :showSize="true"/>
  </div>
    <!-- <b-skeleton height="40px" width="100%" class="mt-2 mb-2" v-show="loading.first"/> -->
    <div class="table-render-skeleton" v-show="loading.first">
      <b-skeleton-table   
        :rows="pagination.limit || 10"    
        :columns="schema.length || 10"
        :table-props="{ }"/>
    </div>
    <modal-filter-deliveries ref="modalFilterDeliveries" @send="filterList" :searchNamePudo="searchNamePudo" :filter.sync="currentFilter" :countries="countries" :mySession="mySession" :formattedOrganization="formattedOrganization" :shippersByOrganization="shippersByOrganization" :deliveryStatusesFull="deliveryStatusesFull"></modal-filter-deliveries>
    <modal-status-delivery ref="modalStatusDelivery" :delivery.sync="currentDelivery" :multipleDelivery.sync="currentMultipleDelivery" @send="savedChangeStatus"></modal-status-delivery>
    </div>
</template>
<script>
import { environment } from '@/environments/environment'
import ModalFilterDeliveries from './ModalFilterDeliveries.vue'
import ModalStatusDelivery from './ModalStatusDelivery.vue'
import BaseServices from '@/store/services/index'
import { mapGetters } from 'vuex'
export default { 
  name: 'deliveries-deliveries',
  components: {ModalFilterDeliveries, ModalStatusDelivery},
  data () {
    return {
      optionsType: [
        { id: 1, text: this.$t('Recibido'), name: 'Received'},
        { id: 2, text: this.$t('En camino'), name:'OnTheWay'}
      ],
      buttons: [{ name: 'delete', text: 'Cambiar estados', color: 'warning', action: this.openModalStatus}],
      controlHeight: { class: 'row mb-2 spacing-label-field'},
      pagination: {
        page: 1,
        total: 40,
        limit: 10
      },
      selectedRows: [],
      schema: [],
      rows: [],
      buttonSend: {icon: 'SearchIcon', color: 'warning', title: 'Filtrar', disabled: true},
      fields: [],
      fieldsType: [],
      actions: [{action: id => this.openModalStatus(id), icon: 'FileTextIcon', color: 'primary', text: 'Editar', disabled: false}],
      formFilter: {},
      currentFilter: {},
      currentDelivery: {},
      currentMultipleDelivery: false,
      formType: {},
      download: false,
      loading: {
        first: false,
        deliveries: false,
        filter: false,
        excel: false
      },
      keyTableRender: 0,
      keyFormRender: 0,
      baseService: new BaseServices(this),
      schemaAdminByType : {
        Received: {label: this.$t('Fecha recepción'), sortable: true, key: 'received_date'},
        OnTheWay: {label: this.$t('Fecha compromiso'), sortable: true, key: 'deadline_date'}
      },
      formattedOrganization: null,
      isPudo: false,
      isAdmin: false,
      countryConfigs: {
        1: [{label:  'Comuna', sortable: true, key: 'pudo.address.place.level1.name'}],
        2: [{label:  'Estado', sortable: true, key: 'pudo.address.place.level4.name'}, 
          {label:  'Municipio', sortable: true, key: 'pudo.address.place.level3.name'}, 
          {label:  'Colonia', sortable: true, key: 'pudo.address.place.level1.name'}
        ],
        3: [
          {label:  'Departamento', sortable: true, key: 'pudo.address.place.level2.name'}, 
          {label:  'Municipio', sortable: true, key: 'pudo.address.place.level1.name'}
        ],
        4: [
          {label:  'Provincia', sortable: true, key: 'pudo.address.place.level2.name'}, 
          {label:  'Distrito', sortable: true, key: 'pudo.address.place.level1.name'}
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      generalLoading: 'getLoading',
      deliveries: 'getDeliveriesPudos',
      shippersByOrganization: 'getShippersByOrganizationSimplifiedQueryParams',
      organizations: 'getOrganizationsGlobal',
      countries: 'getCountries',
      deliveryStatusesFull: 'getDeliveryStatusesPudo',
      levels: 'getLevels'
    })
  },
  watch: {
    generalLoading: {
      handler() {
        this.loading.deliveries = !!this.generalLoading.getDeliveriesPudos
      },
      deep: true
    },
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    deliveries() {
      this.rows = this.deliveries.rows
      this.pagination.total = this.deliveries.pagination.meta.data_count
      this.pagination.page = this.deliveries.pagination.meta.page
      this['pagination.limit'] = this.deliveries.pagination.meta.page_count
    },
    organizations () {
      this.setOrganizations()
    },
    shippersByOrganization () {
      this.setSelectOptions('shipper.id', { options: this.shippersByOrganization })
    },
    'pagination.page' () {
      this.getDeliveries(this.typePudo.name)
    },
    'pagination.limit' () {
      if (this.pagination.page === 1) this.getDeliveries(this.typePudo.name)
    },
    deliveryStatusesFull () {
      this.setStatusDeliveries()
    }
  },
  mounted () {
    this.getAllInitialData()
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    }, 
    setInitialData () {
      this.sessionUser = this.$session.get('cas_user')
      this.isAdmin = ['superadmin', 'admin'].includes(this.sessionUser.role)
      this.isMarketplace = ['marketplace'].includes(this.sessionUser.role)
      this.isPudo = ['pudo'].includes(this.sessionUser.role)
      this.formType.type = this.optionsType[0]
      this.typePudo = this.optionsType[0]
      this.setFields()
      this.setSchema() 
      this.loading.filter = true
      this.indexShipper = this.fields.findIndex(el => el.name === 'shipper.id')
      if (!this.isAdmin) {
        this.setOwnerForNonAdminUsers()
        if (!this.isPudo) {
          this.setDinamicFields()
          this.changeSchemaByCountry()
        } 
      }
      this.setOrganizations()
      this.getDeliveries(this.typePudo.name)
      this.getStatusByType(this.typePudo.name)
    },
    setFields () {
      this.fields = this.isPudo ? [
        {fieldType: 'FieldInput', name: 'references', label: 'N° Seguimiento/OT/Nombre destinatario', useLabel: true},
        {fieldType: 'FieldSelect', name: 'status_id', label: 'Estado del envío'}
      ] : [
        {fieldType: 'FieldSelect', name: 'organization.id', label: 'Empresa', useLabel: true, clearable: true, change: this.changeOrganization, containerClass: ''},
        {fieldType: 'FieldSelect', name: 'shipper.id', label: 'Seller', useLabel: true, clearable: true, dependency: 'organization.id', containerClass: 'hide', useSkeleton: false, change: this.changeShipper},
        {fieldType: 'FieldAutocomplete', name: 'pudo_id', label: 'Punto de retiro', searchOnType: { fx: this.searchNamePudo, nChar: 3, debounce: 300, allowSearchAsValue: true, persistSearch: true }, placeholder:'Ingrese punto de retiro', clearable: true}
      ]
      if (this.isPudo) this.setStatusDeliveries()
      this.fieldsType = [   
        {fieldType: 'FieldRadio', name: 'type', type: 'type', align: 'h', options: this.optionsType, change: this.changeType, containerClass: 'col-12', disabled: true}
      ]
      this.loading.first = true
      this.loading.excel = true
    },
    setOwnerForNonAdminUsers () { 
      !this.isMarketplace ? this.formFilter['shipper.id'] = this.sessionUser.shipper : this.formFilter['organization.id'] = this.sessionUser.organization
      this.payloadFilter = {
        ['shipper.id']: this.sessionUser.shipper?.id, 
        ['country.id']: this.sessionUser.country?.id, 
        ['organization.id']: this.sessionUser.organization?.id
      }
      this.isMarketplace || this.isPudo ? delete this.payloadFilter['shipper.id'] : delete this.payloadFilter['organization.id']
    },
    setDinamicFields () {
      const index = this.fields.findIndex(el => el.label === 'Empresa')
      this.fields[index].containerClass = 'hide'
      this.isMarketplace ? this.changeOrganization('organization', this.sessionUser.organization) : this.fields.push({fieldType: 'FieldInput', name: 'imported_id', label: 'N° de envío'}) 
    },
    setDinamicSchemas () {
      if (this.isMarketplace) {
        const index = this.schema.findIndex(el => el.label === 'Empresa')
        this.schema[index].label = 'Seller'
      }
      else {
        this.schema.shift()
      }
    },
    setSchema () {
      const schemaForPudo = [
        {label: this.$t('N° Seguimiento'), sortable: true, key: 'imported_id', useSlot: true},
        {label: this.$t('OT'), sortable: true, key: 'tracking_number' },
        {label: this.$t('Estado'), key: 'status', useSlot: true},
        {label: this.$t('Nombre destinatario'), sortable: true, key: 'contact_name'},
        {label: this.$t('Comentarios'), sortable: true, key: 'comments'},
        {label: this.$t('Última modificación'), sortable: true, key: 'updated_at'},
        {label: this.$t('Acciones'), key: 'actions', class: ['text-center']}
      ]
      const schema = [
        {label: this.$t('Empresa'), sortable: true, key: 'shipper.name'},
        {label: this.$t('Estado'), key: 'status', useSlot: true},
        {label: this.$t('N° de envío'), sortable: true, key: 'imported_id', useSlot: true},
        {label: this.$t('Orden de flete'), sortable: true, key: 'tracking_number' },
        {label: this.$t('Bodega'), sortable: true, key: 'warehouse.address.full_address'},
        {label: this.$t('Punto de retiro'), sortable: true, key: 'pudo.name'},
        {label: this.$t('Cliente'), sortable: true, key: 'contact_name'},
        {label: this.$t('Localidad'), sortable: true, key: 'pudo.address.place.level1.name'},
        {label: this.$t('Courier'), sortable: true, key: 'carrier.name'},
        {label: this.$t('Fecha creación'), sortable: true, key: 'created_at'}
      ]
      if (!this.isPudo) schema.push(this.schemaAdminByType.Received)
      if (this.isAdmin) this.typePudo.name === 'OnTheWay' ? schema.pop() : schema.push({label: this.$t('Acciones'), key: 'actions', class: ['text-center']})
      this.schema = this.isPudo ? schemaForPudo : schema
    },
    getStatusByType (type) {
      const queryParams = {
        'status_type.name': type
      }
      this.$store.dispatch('fetchService', {name: 'getDeliveryStatusesPudo', queryParams})
    },
    getDeliveries (params) {
      const queryParams = {
        ...this.payloadFilter,
        'paginate_by': this.pagination.limit,
        'page': this.pagination.page,
        'owner.type': params
      }
      this.$store.dispatch('fetchService', { name: 'getDeliveriesPudos', queryParams, onSuccess: () => { this.disabledFieldsRadio(false) }})
    },
    setOrganizations () {
      if (this.organizations && !!this.organizations.length) {
        this.formattedOrganization = this.organizations.map(el => {
          if (el.organization_type.id === 1) {
            return {...el, text: `${el.name} (M)`}
          } else {
            return {...el, text: el.name}
          }
        })
        this.setSelectOptions('organization.id', { options: this.formattedOrganization })
      }
    },
    setStatusDeliveries () {
      if (this.deliveryStatusesFull && !!this.deliveryStatusesFull.length) {
        this.setSelectOptions('status_id', { options: this.deliveryStatusesFull })
      }
    },
    changeType (name, value) {
      this.typePudo = value
      this.clearChangeType()
      const indexDate = this.schema.findIndex(el => el.label === 'Fecha creación') + 1
      if (!this.isPudo) this.schema.splice(indexDate, 1, this.schemaAdminByType[value.name])  
      if (this.isPudo || this.isAdmin) this.typePudo.name === 'OnTheWay' ? this.schema.pop() : this.schema.push({label: this.$t('Acciones'), key: 'actions', class: ['text-center']}) 
      // Validamos si la columna localidad existe si no es asi ha sido modificada para mostrar otros levels
      const indexLocation = this.getPositionFindIndex(this.schema, 'label', 'Localidad')
      // Si se hicieron cambios en la tabla la reiniciamos a como esta originalmente
      if (!!this.isAdmin && indexLocation === -1) this.resetSchema()
      // Obtener los estados segun el tipo de listado que se esta seleccionando
      this.getStatusByType(this.typePudo.name)
      this.pagination.page !== 1 ? this.pagination.page = 1 : this.getDeliveries(this.typePudo.name)
    },
    disabledFieldsRadio (disabled, loading = false) {
      this.fieldsType[0].disabled = disabled
      this.buttonSend.disabled = disabled
      this.loading.first = loading
      this.loading.excel = loading
    },
    clearChangeType () {
      this.rows = [] 
      this.formFilter = {}
      this.isAdmin ? this.payloadFilter = {} : this.setOwnerForNonAdminUsers()
      this.disabledFieldsRadio(true, true)
    },
    filterList (form) {
      this.disabledFieldsRadio(true)
      this.payloadFilter = {}
      if (!this.isAdmin) this.setOwnerForNonAdminUsers()
      const application_form = !!form.form ? form.form : form
      Object.keys(application_form).map(key => {
        this.payloadFilter[key] = application_form[key].id || application_form[key]
      })
      if (!!form?.form) {
        if (!!form.form['organization.id']) {
          this.formFilter['organization.id'] = form?.form['organization.id']
          this.changeOrganization('organization', form.form['organization.id'])
          this.formFilter['shipper.id'] = form?.form['shipper.id']
        } 
        this.formFilter['pudo_id'] = form?.form['pudo_id']
      }
      // Cambiamos las columnas de la tabla segun el país filtrado
      if (!!this.isAdmin && !!this.payloadFilter['country.id']) this.changeSchemaByCountry()
      // Validamos si la columna localidad existe si no es asi ha sido modificada para mostrar otros levels
      const indexLocation = this.getPositionFindIndex(this.schema, 'label', 'Localidad')
      // Si se hicieron cambios en la tabla la reiniciamos a como esta originalmente
      if (!!this.isAdmin && indexLocation === -1 && !this.payloadFilter['country.id']) this.resetSchema()
      this.pagination.page !== 1 ? this.pagination.page = 1 : this.getDeliveries(this.typePudo.name)
      this.keyFormRender++
      this.$bvModal.hide('modalFilterDeliveries')
    },
    cleanFilter () {
      this.disabledFieldsRadio(true)
      this.formFilter = {} 
      this.payloadFilter = {} 
      !this.isAdmin ? this.setOwnerForNonAdminUsers() : this.fields[this.indexShipper].containerClass = 'hide'
      // Validamos si la columna localidad existe si no es asi ha sido modificada para mostrar otros levels
      const indexLocation = this.getPositionFindIndex(this.schema, 'label', 'Localidad')
      // Si se hicieron cambios en la tabla la reiniciamos a como esta originalmente
      if (!!this.isAdmin && indexLocation === -1) this.resetSchema()
      this.pagination.page !== 1 ? this.pagination.page = 1 : this.getDeliveries(this.typePudo.name)
      this.keyFormRender++
    },
    openFilterAdvanced () {
      this.currentFilter = {
        ...this.formFilter
      }
      this.$bvModal.show('modalFilterDeliveries')
    },
    searchNamePudo (value, formModal) {
      const fromForm = !formModal ? this.formFilter : formModal
      const params = {}
      const queryParams =  { 
        'name__like__or__code__like': value,
        'owner.organization.id': fromForm['organization.id'] ?  fromForm['organization.id']?.id  : '',
        'owner.id': fromForm['shipper.id'] ?  fromForm['shipper.id']?.id  : ''
      } 
      Object.keys(queryParams).map(function(key, value) {
        if (queryParams[key] === '') delete queryParams[key]
      })
      return this.baseService.callService('getPudosForType', queryParams, params)
        .then(resp => {
          const response = resp.data.map(el => ({...el, text: el.name}))
          return response
        })
        .catch(err => {
          return err
        })
    },
    setSelectOptions (name, { options }) {
      const index = this.fields.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fields[index].options = options
        this.keyFormRender++
      }
    },
    changeOrganization (name, value) {
      if (!!value?.id) {
        this.fields[this.indexShipper].containerClass = value.organization_type.id === 2 ? 'hide' : 'col-md-4 col-xl-3'
        this.formFilter = {
          ...this.formFilter,
          'shipper.id': null,
          'pudo_id': null
        }
        this.fields[this.indexShipper].options = []
        const queryParams = {}
        const params = {
          organization_id: !!value ? value.id : this.formFilter['organization.id']?.id || 0
        }
        this.fields[this.indexShipper].useSkeleton = true
        this.$store.dispatch('fetchService', { name: 'getShippersByOrganizationSimplifiedQueryParams', queryParams, params, onSuccess: (resp) =>  {
          this.fields[this.indexShipper].useSkeleton = false
          if (value.organization_type.id === 2) {
            this.formFilter['shipper.id'] = {id: resp.data[0].id, text: resp.data[0].name}
          }
        }})
      } else {
        this.fields[this.indexShipper].containerClass = 'hide'
        this.formFilter['shipper.id'] = null
        this.formFilter['organization.id'] = null
        this.keyFormRender++
        return null
      }   
      
    },
    changeShipper (name, value) {
      if (!!value?.id) {
        this.formFilter = {
          ...this.formFilter,
          pudo_id: null
        }
      }
    },
    goTo (id) {
      window.open(`${environment.platformUrl}/deliveries/${id}`, '_blank')
    },
    openModalStatus(id) {
      const delivery = this.rows.filter(el => el.id === id)[0]
      const multipleDelivery = this.rows.filter(el => this.selectedRows.includes(el.id))
      const infoDelivery = multipleDelivery.map(el => {
        return {id: el.id, status: el.status}
      })
      this.currentDelivery = !!id ? delivery : infoDelivery
      this.currentMultipleDelivery = multipleDelivery.length > 0
      this.$bvModal.show('modalStatusDelivery')
    },
    downloadExcel () {
      const queryParams = {
        ...this.payloadFilter,
        ['owner.type']: this.typePudo.name
      }
      this.loading.excel = true
      this.download = true
      const props = {
        name: 'downloadDeliveriesExcel',
        fileName: 'Listado_envíos',
        queryParams,
        onSuccess: this.onLoadedExcel,
        onError: this.onLoadedExcel
      }
      this.$store.dispatch('fetchServiceGenerateExcel', props)
    },
    onLoadedExcel () {
      this.loading.excel = false
      this.download = false
    },
    savedChangeStatus (data) {
      this.$refs.modalStatusDelivery.loading = true
      !!data.multiple ? this.multipleUpdateStatus(data) : this.updateStatus(data)      
    },
    payloadUpdateStatus (data) {
      return {
        current_status_id: data.current_status_id,
        new_status_id: data.new_status_id,
        comment: data.comment || null
      }
    },
    updateStatus (data) {
      const payload = data.payload
      const queryParams = this.payloadUpdateStatus(payload)
      const params = {
        delivery_id: payload.id
      }
      return this.baseService.callService('updateStatusDeliveries', queryParams, params)
        .then(resp => {
          this.successUpdateStatus(data.multiple)
        })
        .catch(err => {
          this.errorUpdateStatus(data.multiple, err)
        })
    },
    multipleUpdateStatus (data) {
      const payload = data.payload
      const services = payload.map(el => {
        const queryParams = this.payloadUpdateStatus(el)
        return { name: 'updateStatusDeliveries', params: { delivery_id: el.id }, queryParams }
      })
      return this.baseService.callMultipleServices(services)
        .then(resp => {
          this.successUpdateStatus(data.multiple)
        })
        .catch(err => {
          this.errorUpdateStatus(data.multiple, err)
        })
    },
    successUpdateStatus (multiple) {
      setTimeout(() => {
        this.selectedRows = []
        this.$refs.modalStatusDelivery.loading = false
        this.$bvModal.hide('modalStatusDelivery')
        this.$success(this.$t(!!multiple ? 'msg-exito-actualizar-multiple' : 'msg-exito-actualizar'))
        this.getDeliveries(this.typePudo.name)
      }, 2000)
    },
    errorUpdateStatus (multiple, err) {
      this.$refs.modalStatusDelivery.loading = false
      this.$alert(this.$t(!!multiple ? 'msg-problema-actualizar-multiple' : 'msg-problema-actualizar', {code: err}))
    },
    resetSchema () {
      this.setSchema()
      if (!this.isAdmin) this.setDinamicSchemas()
      const indexDate = this.schema.findIndex(el => el.label === 'Fecha creación') + 1
      this.schema.splice(indexDate, 1, this.schemaAdminByType[this.typePudo.name])   
    },
    changeSchemaByCountry () {
      // Limpiamos la tabla
      this.resetSchema()
      // Mostramos segun el país las columnas correspondientes
      const index = this.getPositionFindIndex(this.schema, 'label', 'Localidad')
      const country_id = this.payloadFilter['country.id']
      if (this.countryConfigs[country_id]) {
        const columns = this.countryConfigs[country_id]
        this.schema.splice(index, 1, ...columns)
      }
    },
    getPositionFindIndex (origin, type,  name) {
      return origin.findIndex(el => el[type] === name)
    }
  }
  
}
</script>
<style>
@media (max-width: 426px) {
  .flex-mobile-bottom {
    flex-direction: column;
  }
}
</style>